import React from "react";
import styled from "styled-components";

import Container from "../components/container";
import SEO from "../components/SEO";

const StyledOver = styled.div`
    margin: 40px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: auto;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const SecondPage = ({ location }) => (
    <>
        <SEO
            title="Over ons"
            description="Bieke van schoonheidssalon Alegria heeft jarenlange ervaring en biedt tal van kwalitatieve, persoonlijke verzorgingen aan. We werken met zorgvuldig uitgezochte producten, biologisch en zonder parabenen."
            path={location.pathname}
        />
        <Container>
            <h1>Over ons</h1>

            <h2>Welkom op de website van Alegria, het schoonheidssalon dat Bieke in 1992 opstartte.</h2>
            <StyledOver>
                <img className="img-responsive" src="/Bieke.png" alt="Bieke" />
                <div>
                    <p>
                        Vandaag draagt de jarenlange ervaring van Bieke bij tot de kwalitatieve verzorging die u in haar salon mag verwachten. Zij streeft bij
                        ieder naar een <strong>persoonlijke en professionele behandeling</strong>, die resulteert in een aangename ontspannen ervaring.
                    </p>
                    <p>
                        De schoonheidsproducten van <strong>Biodroga</strong> die zij voor u gebruikt, zijn zorgvuldig uitgezocht. Biologisch, zonder parabenen
                        en van een verfijnde hoogwaardige kwaliteit, alsook een specifieke mannenlijn.
                    </p>
                    <p>
                        Tevens vindt Bieke het heel belangrijk om de schoonheidssessies aan de persoonlijke noden van haar klant aan te passen. Daarom voorziet
                        zij voor u graag een professioneel onderbouwde <strong>behandeling op maat</strong> wat betreft uw huidverzorging, hand- en
                        voetverzorging, ... Haar kennis, zorg en ervaring vormt immers de basis voor een gepaste schoonheidsbehandeling tegen een
                        <strong>democratische prijs</strong> waarbij zij tevens hoopt u zo goed mogelijk van dienst te zijn voor een optimale verzorging.
                    </p>
                </div>
            </StyledOver>
        </Container>
    </>
);

export default SecondPage;
